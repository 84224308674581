
import React from "react";

const viewMorePhoneFc: React.FC = () => {

    const viewMore = {
        color:'#666',
        textDecoration:'none',
    }


    return(
       <div className="phone-more">
         <div className="row viewMore container">
        <div className="col" style={{position:'relative',textAlign:"center"}}>
            <a className="pointer" data-bs-toggle="modal" data-bs-target="#showPhoneMoreModal" style={viewMore}>查看更多</a>
            <a className="pointer color-fc creator"  data-bs-toggle="modal" data-bs-target="#showPhoneCreatorModal" style={viewMore}>成为创作者</a>
        </div>
        </div>
       </div>
    )
}

export default  viewMorePhoneFc;