
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import no from '../../../assets/images/creator_stories/no@2x.png';
import { BuryingPointFc } from "../../../components/buryingPoint";
import './components.scss'
interface ViewMoreModalPcProps {

}

const ViewMoreModalPcFc: React.FC<ViewMoreModalPcProps> = (props) => {

    const modalFooterStyle = {
        display: 'flex',
        justifyContent: 'center',
        paddingTop:'0'
    }

    return (
        <>
        <div className="modal fade" tabIndex={-1} id="showPcMoreModal" aria-hidden="true" aria-labelledby="showPcMoreModal" >
            <div className="modal-dialog modal-lg modalCenter" style={{width:'30%'}}>
                <div className="modal-content">
                <div className="modal-header b-0 relative" style={{display:'block'}}>
                        <div className="modal-title text-align-center">查看更多</div>
                        <div className="absolute pointer" data-bs-dismiss="modal" aria-label="Close" style={{width:'30px',right:'0',top:'0'}}>
                        <img src={no} alt="" style={{width:'100%'}} />
                        </div>
                        {/* <button type="button" className="btn-close absolute" data-bs-dismiss="modal" aria-label="Close" style={{right:'5px',top:'5px'}}></button> */}
                    </div>
                    <div className="modal-body" style={modalFooterStyle}>
                        <div>请先登录（或注册）商家中心，即可对接更多创作者前往商家中心</div>
                    </div>
                    <div className="modal-footer b-0" style={modalFooterStyle} data-bs-dismiss="modal">
                        <div className="pointer">
                            <a onClick={()=>BuryingPointFc('VO','VO00300100300302',{action:'点击查看更多弹窗-前往商家中心按钮'})} href="http://j.lidebiji.com/user/login/" target={'_blank'} className="btn btn-danger" style={{ color: '#fff',width:'100%',paddingLeft:'2rem',paddingRight:'2rem' }}>前往商家中心</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" tabIndex={-1} id="showPcCreatorModal" aria-hidden="true" aria-labelledby="showPcCreatorModal" >
            <div className="modal-dialog modal-lg modalCenter" style={{width:'30%'}}>
                <div className="modal-content">
                      <div className="modal-header b-0 relative" style={{display:'block',fontWeight:'700'}}>
                        <div className="modal-title text-align-center">成为创作者</div>
                        <div className="absolute pointer" data-bs-dismiss="modal" aria-label="Close" style={{width:'30px',right:'0',top:'0'}}>
                        <img src={no} alt="" style={{width:'100%'}} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div>{'请通过以下两种方式之一，成为创作者:'}</div>
                        <br />
                        <div>{'方式1: 请在手机应用商店下载“里德笔记”APP，登录（或注册） >设置 >创作者认证'}</div>
                        <br />
                        <div>{'方式2: 前往 “创作者中心”(网站) > 登录（或注册） >头像（右上方）>个人设置 >创作者认证'}</div>
                    </div>
                    <div className="modal-footer b-0" style={modalFooterStyle} data-bs-dismiss="modal">
                        <div className="pointer">
                            <a onClick={()=>BuryingPointFc('VO','VO00300100300402',{action:'点击成为创作者按钮弹窗-前往创作者中心按钮'})} className="btn btn-danger" style={{ color: '#fff',width:'100%',paddingLeft:'2rem',paddingRight:'2rem' }} href="http://c.lidebiji.com/">前往创作者中心</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ViewMoreModalPcFc;