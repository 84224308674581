
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { BuryingPointFc } from "../../../components/buryingPoint";

interface CreatorListProps {
  data;
}

const CreatorListPcFc: React.FC<CreatorListProps> = (props) => {
  const { data } = props;
  const [imageUrl, setImageUrl] = useState<string>("");
  const handleImage = (url: string) => {
    setImageUrl(url);
  };
  return (
    <>
      {data?.map((item) => [
        <>
          <div
            className="row mt-5 pd-2"
            key={item.userId}
            style={{ margin: "0" }}
          >
            <div className="col-2" style={{ width: "200px" }}>
              <div className="row">
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={item.creatorInfo.avatar}
                    alt={""}
                    className="avatar"
                    style={{width:'60px',height:'60px'}}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <div style={{color:'red',wordBreak:'break-word'}}>{item.creatorInfo.nickname}</div>
                  <div className="list-fs" style={{ margin: "10px 0" }}>
                    {item.creatorInfo.career}
                  </div>
                </div>
              </div>
            </div>
            <div className="col list-fs">
              {/* <div dangerouslySetInnerHTML={{ __html: item.creatorInfo.description }}> */}
              <div>{`${item.creatorInfo.description}`}</div>
            </div>
            <div className="col" style={{   textAlign: "right",}}>
              {item?.workQuoteInfo?.map((i) => {
                  return (
                    <div style={{width:'170px'}}>
                      {i.work}
                      <span style={{color: "red" }}>&nbsp;¥{i.price.price}</span>
                    </div>
                  );
              })}
            </div>
            <div className="col-1">{item.creatorInfo.area}</div>
            <div className="col-2" style={{ display: "inline-block" }}>
              {item?.tags?.map((i, index) => {
                return (
                  <>
                    <span>
                      {i.name}
                      {item.tags.length != index + 1 ? <> | &nbsp;</> : <></>}
                    </span>
                  </>
                );
              })}
            </div>
            <div
              className="col"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              {typeof item.urlWorks == "string" && <>{item.works}</>}
              {typeof item.urlWorks != "string" && (
                <>
                  {item.urlWorks?.map((url: string, i: number) => [
                    <div
                      key={i}
                      className="pointer"
                      style={{ margin: "0 2px" }}
                    >
                      <img
                        className="img-fluid"
                        src={url}
                        alt={""}
                        style={{ margin: "5px 5px" }}
                        onClick={() => handleImage(url)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      />
                    </div>,
                  ])}
                </>
              )}
            </div>
            <div
              className="col"
              style={{ display: "flex", justifyContent: "space-evenly" ,alignItems: 'center' }}
            >
              <a
                key={0}
                className="nav-link nav-color-0 pointer"
                style={{
                  border: "1px solid",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  color: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px'
                }}
                onClick={() => {
                  window.open(
                    "http://j.lidebiji.com/user/register"
                  );
                  BuryingPointFc('VO','VO00300100300201',{action:'点击创作者-打招呼按钮'})
                }}
              >
                打招呼
              </a>
            </div>
          </div>
          <div style={{ height: "1px", background: "#D5D5D5" }} />
        </>,
      ])}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <img src={imageUrl} alt={""}></img>
        </div>
      </div>
    </>
  );
};

export default CreatorListPcFc;
