import './index.scss'
import React, {useEffect, useState} from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import NavPcFc from "./Components/navPcFc"
import CreatorsIntroducePcFc from "./Components/creatorsIntroducePcFc"
import CreatorListPcFc from "./Components/creatorListPcFc"
import ViewMorePcFc from "./Components/viewMorePcFc"
import ViewMoreModalPcFc from "./Components/viewMoreModalPcFc"
import NavPhoneFc from "./Components/navPhone"
import CreatorListPhoneFc from "./Components/creatorListPhoneFc"
import ViewMoreModalPhoneFc from './Components/viewMoreModalPhoneFc'
import ViewMorePhoneFc from './Components/viewMorePhoneFc'
import {queryCompanyById, queryCreatorSquareAll} from '../../services/blog'
import pathJson from "../../data/common/path.json"
import {graphql, useStaticQuery} from "gatsby";

const CreatorPage = () => {

    const [dataList, setDataList] = useState<any[]>([])
    const [navActiveId, setNavActiveId] = useState<string>('1000092');
    const [navName, setNavName] = useState<string>('内容运营');
    //   const pathData = useStaticQuery(graphql`
    //   query {
    //     file(relativePath: { eq: "data_common/path.json" }) {
    //       pathJson {
    //         title
    //         path
    //       }
    //     }
    //   }
    // `);

    useEffect(() => {
        handleNavActive('1000092')
    }, []);

    const handleNavActive = async (id: string, name?: string) => {
        console.log("id,name", name, id);
        setNavActiveId(id);
        setNavName(name || '');
        const req = {
            categoryId: +id,
        };
        const res = await queryCreatorSquareAll(req);
        if (res?.header?.success) {
            setDataList(res.creators)
        }
    }

    return (
        <Layout>
            {/*<Seo title={pathData.title}/>*/}
            <Seo title={pathJson.CREATOR_SQUARE.title}/>
            <>
                <div className="pb-5" id="stepsFormCreatorPc">
                    <div className="container bg-fff pd-1">
                        <NavPcFc navActiveId={navActiveId} handleNavActive={handleNavActive}/>
                        <div style={{height: '1px', background: '#eee', margin: '20px 0'}}></div>
                        <CreatorsIntroducePcFc/>
                        <CreatorListPcFc data={dataList}/>
                    </div>
                    <div className='container'>
                        <ViewMorePcFc/>
                        <ViewMoreModalPcFc/>
                    </div>
                </div>
                <div style={{padding: '0.4rem 0', background: '#F5F5F5'}}></div>
                <div className="pb-5" id="stepsFormCreatorPhone">
                    <NavPhoneFc
                        navActiveId={navActiveId}
                        handleNavActive={handleNavActive}
                        navName={navName}
                    />
                    <CreatorListPhoneFc data={dataList}/>
                </div>
                <ViewMorePhoneFc/>
                <ViewMoreModalPhoneFc/>
            </>
        </Layout>
    )
}

export default CreatorPage;